
.customScroll::-webkit-scrollbar{
	width: 10px;
	background-color: transparent;
}

.customScroll::-webkit-scrollbar-track{
	border-radius: 10px;
	background-color: transparent;
}

.customScroll::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #D9D9D9;
}
